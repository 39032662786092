<template>
    <div class="app-body monthCard">
        <div class="a-flex-rsbc a-ptb-6">
            <span class="a-fs-16 a-fw-b">活动消耗记录</span>
        </div>
        <el-card class="el-main">
            <le-search-form @reset="handlerRest" @search="handlerSearch" :canPackUp="true" id="leSearch">
                <le-input label="活动ID" v-model="pageParam.params.activityId" />
                <le-input label="活动名称" v-model="pageParam.params.activityName" />
                <le-input label="用户ID" v-model="pageParam.params.userId" />
                <le-input label="用户手机号" v-model="pageParam.params.mobile" />
                <le-select-local-search label="活动类型" v-model="pageParam.params.activityType" :options="optionsActivityType" />
                <jd-datetime-range 
                    :large="true" 
                    label="发放时间" 
                    valueFormat="timestamp" 
                    :minDate.sync="pageParam.params.recordStartTime" 
                    :maxDate.sync="pageParam.params.recordEndTime" />
            </le-search-form>
            <le-pagview ref="activityPage" @setData="setTableData" :pageParam="pageParam" :isFixed="true" :tableRef="$refs.activityPagelist">
                <el-table ref="activityPagelist" class="activityPagelist" :data="tableData" :highlight-current-row="true"  style="width: 100%" @filter-change='filterFun' v-sticky="{ top: 0, parent:'.el-card__body' }">
                    <el-table-column label="用户ID" prop="userId" min-width="100"></el-table-column>
                    <el-table-column label="用户手机号" prop="mobile" min-width="160"></el-table-column>
                    <el-table-column 
                        label="领取活动ID"
                        min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.activityId || '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column 
                        label="活动名称"
                        min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.activityName || '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column 
                        label="活动类型"
                        min-width="160">
                        <template slot-scope="{ row }">
                            <span>{{ row.activityType | initDic(optionsActivityType) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column 
                        label="发放时间"
                        min-width="200">
                        <template slot-scope="{ row }">
                            <span>{{ row.recordTime || '-' }}</span>
                        </template>
                    </el-table-column>
                </el-table>
                <template slot="footerContent" slot-scope="scope" >
                    <div class="a-flex-rfsc" v-if="scope.val != -1">
                        <span class="a-fs-12 a-c-normal">发放总数</span>
                        <span class="a-fs-16 a-c-master a-fw-b a-plr-7">{{scope.val.num || 0}}</span>
                        <span class="a-fs-12 a-c-normal a-plr-10">张</span>
                    </div>
                </template>
            </le-pagview>
        </el-card>
    </div>
</template>

<script>
    import util from '../../../src/utils/util'
    export default {
        data () {
            return {
                util: util,
                tableData: [],
                pageParam: {
                    url: this.$Config.apiUrl.activityUserRecordPage,
                    method: "post",
                    params: {
                        activityId: this.$route.query.id, //活动id
                        activityName: '', //活动名称
                        mobile: '',//
                        userId: '',//
                        activityType: '',
                        recordEndTime: '',// 关联套餐id
                        recordStartTime: '',
                    },
                    freshCtrl: 1,
                },
                optionsActivityType: []
            }
        },
        created () {
            this.$getDic('activityRewardsType','select').then(res=>{ this.optionsActivityType = res; })
        },
        methods: {
            //获取列表
            setTableData(data) {
                this.tableData = data;
            },
            handlerRest() {
                this.pageParam.params = {
                    activityId: this.$route.query.id, //活动id
                    activityName: '', //活动名称
                    mobile: '',//
                    userId: '',//
                    activityType: '',
                    recordEndTime: '',// 关联套餐id
                    recordStartTime: '',
                };
                this.handlerSearch()
            },
            handlerSearch() {
                this.$refs['activityPage'].pageNum = 1
                this.pageParam.freshCtrl++;
            },
           
        }
    }
</script>

<style lang="scss" scoped>
.activityPagelist{
    /deep/ .el-table__body-wrapper::-webkit-scrollbar {
        /*width: 0;宽度为0隐藏*/
        width: 0 !important;
        height: 0 !important;
    }
}

/deep/ .el-table__fixed, /deep/ .el-table__fixed-right{
    height: 100% !important;
}
.stationTag{
    padding: 0px 6px;
    background: #f4f4f5;
    color: #909399;
    border: 1px solid #e9e9eb;
    border-radius: 3px;
    margin-right: 4px;
    margin-bottom: 4px;
    font-size: 12px;
    word-break: keep-all;
}
</style>